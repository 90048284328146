import DanzzaBold from '../../fonts/Danzza-Bold.woff2'
import DanzzaRegular from '../../fonts/Danzza-Regular.woff2'
import DanzzaLight from '../../fonts/Danzza-Light.woff2'

// you can include multiple fonts and include them in this array
export default [
  {
    name: 'Danzza',
    files: [DanzzaBold],
    options: {
      fontStyle: 'normal',
      fontWeight: '700',
      fontDisplay: 'swap',
    },
  },
  {
    name: 'Danzza',
    files: [DanzzaRegular],
    options: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontDisplay: 'swap',
    },
  },
  {
    name: 'Danzza',
    files: [DanzzaLight],
    options: {
      fontStyle: 'normal',
      fontWeight: '300',
      fontDisplay: 'swap',
    },
  },
]

export default {
    colors: {
        text: '#000',
        link: '#808080',
        black: '#000',
    },
    metrics: {
        containerWidth: '80rem',
        mainMenuWidth: 80,
        menuWidth: 200,
    },
}
